import React from "react";
// import styles from "./Footer.module.css";
import "./Footer.module.css";

export const Footer: React.FC = () => {
    return (
        <footer>

        </footer>
    );
}